<template>
    <div>
        <modal :name="modal_name" class="select-addons-modal final-modal final-modal popup-modal-all-width-height" transition="nice-modal-fade">
            <div class="v-modal-content">
                <div class="v-modal-header">
                    <span class="v-modal-dialog-title v-modal-title">EDIT MODIFIERS</span>
                    <span @click="hideSelectAddons()">
                        <i class="icon icon-close pointer"></i>
                    </span>
                </div>
                <div class="general-section" style="padding: 15px 0px;">
                <div class="v-modal-body" style="background-color: #fff !important;">                 
                    <div class="">
                        <div class="infoHeader">
                            <div class="descriptionheader">
                                <div class="description-text-header-left">
                                    <h5>
                                        <span class="font-weight-bold text-secondary fs-16 whiteSpace">Modifiers</span>
                                    </h5>
                                </div>
                                <div class="description-text-header-right">
                                    <span @click="expandModifiers()" class="collapse-addon">
                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/addon-collapse.svg" :class="{'iconRotate':show_modifiers == true}" class="w-30 rotateIcon">
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div v-show="show_modifiers == true" v-for="(modifier,modifier_index) in edit_modifiers" :key="modifier_index">
                            <div class="infoHeader">
                                <div class="descriptionheader">
                                    <div class="description-text-header-left">
                                        <h5 style="width: max-content;">
                                            <div class="form-check form-check-inline mr-5">
                                                <label class="form-check-label position-relative text-center" style="padding-left: 0 !important;">
                                                    <div class="modifier-default" :class="{'modifier-default-selected': modifier.show_items}">
                                                        <avatar v-if="modifier.image == '' || modifier.image == null"
                                                            :username="modifier.modifier_name.replace(/ .*/,'')"
                                                            :src="''"
                                                            :size="100"
                                                            :rounded="true"
                                                            style="width: 35px !important; height: 35px !important; margin-left: auto !important; margin-right: auto !important;"
                                                            color="#000"
                                                        ></avatar>
                                                        <img v-else :src="modifier.image" width="35" height="35" alt="Logo" />
                                                        <input type="radio"
                                                            class="form-check-input position-absolute" style="left: 20px;top: 10px;"
                                                            v-model="modifier.show_items"
                                                            name="modifier_name" />
                                                        <span class="radio-icon d-none"></span>
                                                    </div>
                                                    <span class="text-capitalize" style="padding-left: 5px !important;padding-top: 2px;">{{modifier.modifier_name}} <span v-if="modifier.modifier_as == 'mandatory'" class="text-danger">*</span> </span>
                                                </label>
                                            </div>
                                        </h5>
                                    </div>
                                    <div class="description-text-header-right-small">
                                        <span class="collapse-addon" @click="collapseItems(modifier_index)" >
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/main-collapse.svg" v-if="modifier.show_items" style="width:25px;">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/arrow-down.svg"  style="width:25px;" v-else>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div :class="{'d-block':modifier.show_items, 'd-none': !modifier.show_items}">
                                <div class="row modifier-checkbox">
                                    <div v-for="(modifier_item, modifier_group_item_index) in modifier.modifier_list" :key="modifier_group_item_index+'mmindexddff'" class="d-inline-block m-3 modifier-checkbox-ui">
                                        <label class="form-check-label position-relative flex-column text-center" style="width: 120px; padding-left: 0 !important;">
                                            <div class="modifier-default pointer w-100" :class="{'modifier-default-selected': modifier_item.checked}">
                                                <avatar
                                                    :username="modifier_item.item.trim()"
                                                    :src="''"
                                                    :size="100"
                                                    :rounded="true"
                                                    style="width: 50px !important; height: 50px !important; margin-left: auto !important; margin-right: auto !important;"
                                                    color="#000"
                                                ></avatar>
                                                <input type="checkbox"
                                                    class="form-check-input position-absolute pointer" style="left: 20px;top: 10px;"
                                                    v-model="modifier_item.checked"
                                                    name="modifier_name" />
                                                <span class="checkbox-icon d-none"></span>
                                            </div>
                                            <span class="text-capitalize d-block" style="padding-left: 5px !important;padding-top: 2px;width: 120px;">
                                                <span class="fs-13" style="word-break: break-all;">{{modifier_item.item}}</span>
                                            </span>
                                        </label>
                                        <!-- <el-checkbox v-model="modifier_item.checked" @change="modifierItemChange($event, modifier_index, modifier_group_item_index)" class="mr-5 text-capitalize">{{modifier_item.item}}</el-checkbox> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div class="v-modal-dialog-actions modal-bg text-center pt-0">
                    <button @click="saveAddons()" :disabled="savedaddons" class="pointer w-100 d-flex justify-content-between align-items-center bg-blue-image p-3 bg-orange-image mt-3 b-r-7">
                        <span class="font-weight-bold fs-16">Save</span>
                        <span>
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/save-arrow.svg">
                        </span>
                    </button>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
import Avatar from 'vue-avatar'
export default {
    props:['modal_name','item','notes','storeorderId'],
    data(){
        return{
            show_modifiers: true,
            showSectionAddons: 0,
            showNotes: true,
            showPreferences: true,
            showSectionAddons: [],
            addons:[],
            variants:[],
            variant_id: "",
            selected_addons:[],
            preferences:[],
            edit_modifiers:[],
            selected_addon_ids:[],
            selected_id:null,
            addon_quantity:1,
            note:this.notes.value,
            items_qty:1,
            store_addons: [],
            existing_addons:[],
            update_addons: false,
            select_mandatory_modifier: false,
            savedaddons: false
        }
    },
    components:{
        Avatar
    },
    methods:{
        expandNotes(){
            if(!this.showNotes){
                this.showNotes = true;
            }
            else{
                this.showNotes = false;
            }
        },
        expandPreferences(){
            if(!this.showPreferences){
                this.showPreferences = true;
            }
            else{
                this.showPreferences = false;
            }
        },
        collapseItems(index){
            this.edit_modifiers[index].show_items = !this.edit_modifiers[index].show_items;
            this.$forceUpdate();
        },
        async saveAddons(){
            this.savedaddons = true
            this.$validator.validate().then(result => {
                if (result == true) {
                    this.store_addons = this.selected_addons
                    let params = {
                        "item_id": this.item._id,
                        "quantity": this.item.quantity,
                        "variant_id": this.item.variant_id,
                        "addons":this.store_addons,
                        "is_customizable":true,
                        "note":this.note,
                        "modifiers": []
                    }
                    localStorage.setItem('edit_modifiers_payload_data',JSON.stringify(this.edit_modifiers))
                    let modifiers = JSON.parse(localStorage.getItem('edit_modifiers_payload_data'))
                    params.modifiers = modifiers
                    // this.select_mandatory_modifier = true;
                    params.modifiers.forEach((modifier, modifier_index) => {
                        delete modifier.show_items
                        modifier.modifier_list.forEach((modifier_item, modifier_item_index) => {
                            if(modifier_item.checked){
                                // if(modifier.modifier_as == 'mandatory' && modifier_item.checked == true){
                                //     this.select_mandatory_modifier = false;
                                // }
                                params.modifiers[modifier_index].modifier_list[modifier_item_index] = params.modifiers[modifier_index].modifier_list[modifier_item_index].item
                            }
                            else{
                                // params.modifiers[modifier_index].modifier_list.splice(modifier_item_index,1)
                                delete params.modifiers[modifier_index].modifier_list[modifier_item_index]
                            }
                        })
                        modifier.modifier_list = modifier.modifier_list.filter((x) => x != 'empty')
                    });
                    params.modifiers.forEach((modifier, modifier_index) => {
                        if(modifier.modifier_list.length == 0) {
                            // params.modifiers.splice(modifier_index,1)
                            delete params.modifiers[modifier_index]
                        }
                    });
                    params.modifiers = params.modifiers.filter((x) => x != 'empty')
                    // if(this.select_mandatory_modifier) {
                    //     this.$toasted.global.error('Please Select Mandatory Modifiers.')
                    //     this.savedaddons = false
                    // } else {
                        return new Promise(async (resolve, reject) => {
                            let response = await this.$http.post("/mobile/orders/items/edit", params)
                            this.savedaddons = false
                            if (response.data.status_id == 1) {
                                resolve(response.data)
                                this.$toasted.global.success('Updated Modifiers Successfully');
                                this.$emit('hideEditModifiers');
                                this.$emit('update_addon');
                                // this.$emit('update_note',this.note,this.item)
                                this.$modal.hide(this.modal_name);
                                localStorage.removeItem('edit_modifiers_payload_data');
                                localStorage.removeItem('item_modifiers');
                            } else {
                                reject(response.data.reason)
                                this.$toasted.global.error(response.data.reason);
                            }
                        })
                    // }
                }
                else{
                    this.$toasted.global.error('Item Quantity must be greater than 0');
                    this.savedaddons = false
                }
            })        
        },
        hideSelectAddons(){
            this.$emit('hideEditModifiers');
            this.$modal.hide(this.modal_name);
            localStorage.removeItem('item_modifiers');
        },
        expandModifiers(){
            if(!this.show_modifiers){
                this.show_modifiers = true;
            }
            else{
                this.show_modifiers = false;
            }
        },
        async getAddons(){
            let params = {
                "product_id": this.item.product_id,
                "item_id": this.item._id,
                "order_id":this.storeorderId
            }
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post("/add_ons/addon/get_addons_for_category", params)
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async loadModifiers(params){
            let response  = await this.getAddons(params);
            this.addons = response.addons;
            this.edit_modifiers = response.modifiers
            this.preferences = response.preferences
            localStorage.setItem('item_modifiers',JSON.stringify(this.item.modifiers))
            let item_modifiers = JSON.parse(localStorage.getItem('item_modifiers'))
            item_modifiers.forEach((el, index) => {
                el.modifier_list = el.modifier_list.map((str, index_item) => ({ item: str, checked: true, id: index_item+1}));
            })
            this.edit_modifiers.forEach((el, index) => {
                el.show_items = false
                el.modifier_list = el.modifier_list.map((str, index_item) => ({ item: str, checked: false, id: index_item+1}));
                item_modifiers.forEach((modifier, modifier_index) => {
                    if(el._id == modifier._id){
                        el.show_items = true
                        el.modifier_list.forEach((modifier_item, modifier_item_index) => {
                            item_modifiers[modifier_index].modifier_list.forEach((item, item_index) => {
                                if(modifier_item.item == item.item){
                                    el.modifier_list[modifier_item_index].checked = true
                                }
                            })
                        })
                    }
                })
            })
        }, 
    },
    computed: {
        loggedInUser() {
            return this.$store.getters.loggedInUser;
        },
        // addon_names(){
        //     let addon_list = []
        //     this.addons.forEach(addon => {
        //         addon_list.push(...addon.addons.map(x =>{return {_id:x._id, quantity:x.quantity, selected_quantity:x.selected_quantity}}))
        //     });
        //     return addon_list 
        // },
        // productPrice(){
        //         var string = this.item.product_price.replace(',','')
        //         var res = parseFloat(string.slice(1,string.length))
        //         return Number(res * this.item.quantity + this.addonPrice)
        // },
        // addonPrice(){
        //     return this.selected_addons.reduce((total, addon) => total += parseFloat(addon.price), 0)
        // }
    },
    created(){
        
    },
    mounted(){
        this.loadModifiers();
        // setTimeout(() => {
        //     this.addons.forEach((addon,index) => {
        //         addon.addons.forEach(addon_list => {
        //             if(addon_list.selected_quantity > 0){
        //                 this.selected_addon_ids.push(addon_list._id)
        //                 this.selected_addons.push({'_id':addon_list._id,'quantity':addon_list.selected_quantity,'price':addon_list.price})
        //                 this.existing_addons.push({'_id':addon_list._id,'quantity':addon_list.selected_quantity,'price':addon_list.price})
        //             }
        //         })
        //     })
        //     this.addons.forEach((section) => {
        //         this.showSectionAddons.push(section.addon_section_name)
        //     })
        // }, 1000);
    },
    beforeMount() {
        this.addons = JSON.parse(JSON.stringify(this.addons));
    },
    beforeDestroy(){ 
        this.$validator.pause()
        localStorage.removeItem('item_modifiers');
        localStorage.removeItem('edit_modifiers_payload_data');
    }
}
</script>                        
<style scoped>
.unavailable-addons {
  opacity: 0.6 !important;
  pointer-events: none;  
  position: relative;
  vertical-align: middle;
  top: 28%;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: red;
  font-weight: 600;
  height: 26px;
  line-height: 26px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.88);
  text-align: center;
}
.whiteSpace{
    white-space: nowrap;
}
.bg-orange-image:focus{
    outline: 0px auto -webkit-focus-ring-color!important;
    color: #303031!important;
}
.addon-col{
    padding-left: 8px;
    margin-top: 2px;
    padding-right: 8px;
}
.avatar-addons {
    font-size: 50px !important;
    line-height: 50px;
    background-color: transparent!important;
}
.avatar-addons {
    width: 50px;
    min-width: 50px;
    height: 50px;
    line-height: 50px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    font-weight: 500;
    text-align: center;
    color: #ffffff !important;
    background-color: transparent!important;
}
.word-break{
    word-break: break-word;
}
.variant-addon{
    cursor: pointer;
    width: 120px;
    height: 88px;
    text-align: center;
    border-radius: 7px;
    padding-top: 17px;
    box-shadow: 0px 5.5px 17px 0px rgba(0, 0, 0, 0.16);
}
.selected_variant{
    background-color: #00448b;
    color: #fff;
}
.addon-border{
  position: relative;
  bottom: 11px;
  border-bottom: 0.1px solid #b2b2b3;
}
.collapse-addon{
    position: relative;
    display: block;
    float: right;
    cursor: pointer;
}
.v-modal-layout{
    overflow: initial !important;
}
.addon-main{
    width: 121px;
    margin-left: 7px;
}
.addon-note{
    height: 96px!important;
    border: 1px solid #b2b2b3!important;
    border-radius: 7px!important;
    padding: 10px 6px!important;
}
.addon-quantity{
    position: relative;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}
.w-52{
    width: 62px !important;
}
.total-addon{
    box-shadow: 0 2px 4px 0 rgba(162, 141, 141, 0.16);
    background-image: linear-gradient(97deg, #005dae 20%, #004fe3 99%);
    border-radius: 7px;
}
.add-custom-addon{
    width: 75px !important;
    border-bottom: none !important;
    color: #fff !important;
}
.bg-green-image{
    background-image: linear-gradient(83deg, #1a9347 8%, #1ca04d 53%, #20b256 93%);
}
.bg-blue-image{
    background-image: linear-gradient(92deg, #004fe3 0%, #005dae 103%);
}
.bg-orange-image{
     background-image: linear-gradient(315deg, #fccb4a -30%, #f0712a 48%);
}
.b-r-7{
    border-radius: 7px;
}
.w-30{
    width: 28px;
}
.rotateIcon{
    transform: rotate(180deg);
}
.iconRotate{
    transform: rotate(0deg) !important;
}
.content-center{
    margin: 0 auto;
    position: relative;
    bottom: 12px;
}
.preferences{
    border-radius: 18px;
    border: solid 1px #b2b2b3;
    padding: 5px 10px 5px 10px;
    display: inline-block;
    margin-left: 4px;
}
.modifier-default {
    background-color: #eff1f4;
    padding: 10px;
    border-radius: 5px;
}
.modifier-default-selected {
    background-color: #206ad3;
}
</style>